import React from 'react'

function Safety() {
    return (
        <div className="RecTop">
            <img style={{width:"100%"}} src="/img/AdvertisingArea/safety care.png" alt="safety care.png"/>
        </div>
    )
}

export default Safety
