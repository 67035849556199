import React from 'react'
import TestimonialsPage from "../ClientSay/TestimonialsPage";

function WhyChoose() {
    return (
        <div className="">
            <div className=" RecTop"> <br /> <br />
                <h2 style={{textAlign:"center"}}> <b>What Clients say about us?</b> </h2>
                <TestimonialsPage />
            </div>
            <div className=" RecTop">
                <img style={{ width: "100%" }} src="img/AnotherPic/why-choose-us.png" alt="why-choose-us.png" />
            </div>
        </div>
    )
}

export default WhyChoose
