import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import './ClientSay.css'

export default class TestimonialsPage extends Component {
  render() {
    return (
      <Carousel 
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          <img src="img/AnotherPic/rupayan.png" />
          <div className="myCarousel">
            <h3>KM Ali</h3>
            <h4>CEO At RUPAYAN GROUP</h4>
            <p>
              Good communication with efficient & thorough. Excellent service, well-behaved, on-time arrived. <br /> Thank you.
            </p>
          </div>
        </div>

        <div>
          <img style={{height:"150px"}} src="img/AnotherPic/tanzimul.jpg" />
          <div className="myCarousel">
            <h3>Mohammad Saifullah</h3>
            <h4>Principal At Tanzimul Ummah Foundation</h4>
            {/* <h4>Vice Chairman at Tanzimul Ummah Foundation</h4> */} 
            <p>
              Timely arrived, the expert was very professional and efficient. Maintain whole process and we are very satisfied.
            </p>
          </div>
        </div>

        <div>
          <img src="img/AnotherPic/ebl.jpg" />
          <div className="myCarousel">
            <h3>Tapash Chakraborty</h3>
            <h4>Head of Property and Premises Management.</h4>
            <p>
              Very useful and handy. Service quality was great. service experience very fantastic.
            </p>
          </div>
        </div>


      </Carousel>
    );
  }
}