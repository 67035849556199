import React from 'react'

function Available() {
    return (
        <div>
            {/* <div style={{textAlign:"center"}}> <br /> <br />
                <img className="Cir_Img" src='https://www.motortrend.com/uploads/sites/11/2019/07/Lotus-16.jpg' alt='cats' />
            </div> */}
            <div className=" RecTop">
                <img style={{ width: "100%" }} src="img/AnotherPic/avail-service.png" alt="avail-service.png" />
            </div>
        </div>
    )
}

export default Available
